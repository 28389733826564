import { gql } from '@apollo/client';

export const listStrategiesQuery = gql`
  query ListStrategies {
    listStrategies {
      items {
        vaultName
        vaultAddress
        strategy
        strategyType
        chainLink
        chainId
        executionDelay
        twapSlow
        twapFast
        extremeVolatility
        highVolatility
        someVolatility
        dtrDelta
        minDtr
        priceChange
        basePosConcentration
        limitPosConcentration
        concentrationMultiplier
        pendingDeposits
        pendingTokensPct
        maxSwapPct
        baseLiquidityIncreasePct
        gasOracle
        gasTolerance
        quoteTokenReserve
        boi
        eoi
        eui
        bui
        minTimeBetweenRebalances
        paused
        chainLinkBalance
        chainLinkMinBalance
        basePosWidth
        maxPosShift
        tickPeg
        posRadius
        numObservations
        observationPeriod
        hysteresis
        posWidth
        maxPosWidth
        increaseLiquidityPct
      }
    }
  }
`;
