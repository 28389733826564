/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { RebalancingStrategies } from 'common/models/strategies';
import { StyledFlexRow, StyledTableBodyTr, StyledTdSub } from 'common/styles/common.styles';
import React from 'react';
import { StyledStrategiesSubTextOne } from './strategiesTable.style';

type Props = {
  strategy: RebalancingStrategies;
  isShown: boolean;
};

const MonitorVaultsTablePositionDetails: React.FC<Props> = (props) => {

  const strategyParamNames = [
    'executionDelay',
    'twapSlow', 
    'twapFast',
    'extremeVolatility',
    'highVolatility',
    'someVolatility',
    'dtrDelta',
    'minDtr',
    'priceChange',
    'basePosConcentration',
    'limitPosConcentration',
    'concentrationMultiplier',
    'basePosWidth',
    'maxPosShift',
    'tickPeg',
    'posRadius',
    'numObservations',
    'observationPeriod',
    'hysteresis',
    'posWidth',
    'maxPosWidth',
    'increaseLiquidityPct',
    ];

  const strategyParamNames2 = [
    'vaultAddress',
    'strategy',
    'pendingDeposits',
    'pendingTokensPct',
    'maxSwapPct',
    'baseLiquidityIncreasePct',
    'gasOracle',
    'gasTolerance',
    'quoteTokenReserve',
    'boi',
    'eoi',
    'eui',
    'bui',
    'minTimeBetweenRebalances'
  ];

  if (props.isShown) {
    return (
      <StyledTableBodyTr key={`${props.strategy.vaultAddress}1`} className="table-border-bottom">
        <StyledTdSub colSpan={5}>
          <StyledFlexRow>
            <StyledStrategiesSubTextOne className="primary-text-color">
            {strategyParamNames.map((p) => props.strategy[p] 
              ? (<span key={p}>{p}: {props.strategy[p]}</span>) 
              : (<span key={p}></span>)
            )}
            </StyledStrategiesSubTextOne>
            <StyledStrategiesSubTextOne className="primary-text-color">
            {strategyParamNames2.map((p) => (props.strategy[p] && !(props.strategy[p] === '0x0000000000000000000000000000000000000000')) 
              ? (<span key={p}>{p}: {props.strategy[p]}</span>) 
              : (<span key={p}></span>)
            )}
            </StyledStrategiesSubTextOne>
          </StyledFlexRow>
        </StyledTdSub>
      </StyledTableBodyTr>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (<></>);
  }
};

export default MonitorVaultsTablePositionDetails;
