/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import Image from 'common/components/Image';
import { Table, TableHeader } from 'common/models/table';
import { StyledFlexAlignCenter, StyledTable, StyledTableHeadingTr, StyledTableTh } from 'common/styles/common.styles';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { TooltipWrapper } from '../Tooltip/tooltip.style';
import { RebalancingStrategies } from 'common/models/strategies';
import StrategiesTablePosition, { getVaultDisplayName } from './StrategiesTablePosition';
import { VAULTS } from '@ichidao/ichi-sdk/dist/src/constants/vaults';
import { MonitoredAmm } from 'constants/constants';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { FarmUtils } from 'utils/farmUtils';

type Props = {
  table: Table;
  strategies: RebalancingStrategies[];
  vaults: MonitorVaults[];
  farms: AppSyncFarm[];
  filter: MonitoredAmm;
  handleSorting: (sortBy: string | undefined) => void;
  criterion: string | undefined;
  pausedOnly?: boolean;
};

const StrategiesTable: React.FC<Props> = (props) => {
  const farms = props.farms;
  const vaults = props.vaults;
  const getFarmByVault = (vault: MonitorVaults) =>{
    return farms.filter((f) => f.lpAddress.toLowerCase() === vault?.address.toLowerCase())[0];
  }; 
  const getVaultByAddress = (address: string) =>{
    return vaults.filter((v) => v.address.toLowerCase() === address.toLowerCase())[0];
  }; 
  const getVaultTVL = (address: string) => {
    const vault = getVaultByAddress(address);
    const farm = getFarmByVault(vault);
    return vault?.tvl ? vault?.tvl * FarmUtils.getBaseTokenPrice(farm) : 0;
}; 
  const chainFilterCriteria = (s: RebalancingStrategies) => { 
    const vaultName = s.vaultName || '';
    if(VAULTS[vaultName]){
      return Number(s.chainId) === props.filter.chain 
        && (VAULTS[vaultName][s.chainId].displayName.includes(props.filter.indicator)
        || VAULTS[vaultName][s.chainId].displayName.includes(props.filter.name.replace(' ', '').toLowerCase())); 
    } else{
      return Number(s.chainId) === props.filter.chain 
        && vaultName.includes('_'+props.filter.name.replace(' ', '').toLowerCase()+'_'); 
    }
  };
  const sortingCriteria = (a: RebalancingStrategies, b: RebalancingStrategies, sortingParam: string | undefined) => {
    if (sortingParam === 'chainLinkBalance') {
      return Number(a.chainLinkBalance) - Number(b.chainLinkBalance);
    } else if (sortingParam === 'tvl') {
      return getVaultTVL(b.vaultAddress) - getVaultTVL(a.vaultAddress);
    } else {
      if (VAULTS[a.vaultName] && VAULTS[b.vaultName]){
        return (VAULTS[a.vaultName][a.chainId].displayName.toLowerCase() > VAULTS[b.vaultName][b.chainId].displayName.toLowerCase()) ? 1 : -1;
      } else {
        return getVaultDisplayName(a.vaultName).toLowerCase() > getVaultDisplayName(b.vaultName).toLowerCase() ? 1 : -1;
      }
    }
  };
  const showPausedCriteria = (s: RebalancingStrategies) => { 
    return !props.pausedOnly || s.paused; 
  };
  const filterCriteria = (s: RebalancingStrategies) => { 
    return props.pausedOnly ? showPausedCriteria(s) : chainFilterCriteria(s); 
  };

  const strategies = [...props.strategies]?.filter((v) => {return filterCriteria(v);})
    .sort((a, b) => sortingCriteria(a, b, props.criterion));

  const getHeaderElem = (header: TableHeader) => {
    return header.sortBy ? (
      <div 
        className="mr-5 cursor-pointer underline" 
        onClick={() => {props.handleSorting(header.sortBy);}}>
        {header.name}
      </div>
    ) : (
      <div className="mr-5">{header.name}</div>
    );
  };

  return (
    <StyledTable>
      <thead>
        <StyledTableHeadingTr className="table-border-bottom">
          {props.table?.headers?.map((header) => (
            <StyledTableTh key={header.name} className="sub-text-color">
              <StyledFlexAlignCenter>
                {getHeaderElem(header)}
                {header.helpText && (
                  <TooltipWrapper>
                    <Tooltip message={header.helpText} position="top" bubbleSize={{ width: '200px' }}>
                      <Image className="icon-help" alt={header.helpText} />
                    </Tooltip>
                  </TooltipWrapper>
                )}
              </StyledFlexAlignCenter>
            </StyledTableTh>
          ))}
        </StyledTableHeadingTr>
      </thead>
      <tbody>
        {strategies?.map((strategy) => (
          <StrategiesTablePosition 
            key={strategy.vaultAddress} 
            strategy={strategy} 
            tvl={getVaultTVL(strategy.vaultAddress)}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};

export default StrategiesTable;
